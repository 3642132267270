.square-blocks {
	@extend .grid-x;
	align-items: flex-start;
	position: relative;
	z-index: 1;
}

.half-block {
	@extend .small-12;
	@extend .medium-6;
	@extend .cell;
	padding: 3rem;
	
	&.-bg-image,
	.-bg-image {
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		padding-bottom: 100%;
		position: relative;
		min-height: 250px;
	
		& .card-slide-container {
			width: 100%;
			height: 100%;
		}
		
	}
}
.content {
	padding: 3rem;
	min-height: 0px !important;
}

@media only screen and (min-width: 1023px) {
	.half-block {
		padding: 5rem 5vw;
	}
	.content {
		padding: 2rem 5vw;
		min-height: 0px;
	}
}

@media only screen and (min-width: 640px) {
	.square-blocks {
		align-items: stretch;
	}
	.half-block,
	.content {
		padding: 3rem 5vw;
		min-height: 50vw;
	}
	&.-bg-image,
	.-bg-image {
		padding-bottom: 50%;
	}
}
.image-slide-container {
	position: absolute;
		top: 0;
		left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	.bx-wrapper {
		max-height: 100%;
		height: 100%;
	}
	.bx-viewport {
		max-height: 100%;
		height: 100% !important;
	}	
}
.vendor-block {
	padding: 5rem 5vw;
	min-height: 50vw;
	
	.logo {
		margin-bottom: 5vh;
		padding-bottom: 2rem;
	}
}
.map {
	
	& > img {
		min-height: 60vh;
		max-height: 70vh;
		max-width: 100%;
		width: auto;
	}
}

.card-slides {
	.bx-wrapper,
	.bx-viewport {
		height: 80% !important;
		background: #fff;
	}
}
.card-name {
	position: absolute;
		bottom: 0;
		left: 0;
	width: 100%;
	background: #fff;
	padding: .5rem;
	z-index: 2;
	
	.bx-wrapper {
		position: inherit !important;
		height: 1rem;
		width: auto !important;
		height: auto !important;
	}
	p {
		margin-bottom: 0px;
	}
}

.sm-square {
	width: 100%;
	height: 100%;
	border-collapse: collapse;
}
.sm-link {
	width: 33.33333333%;
	padding-bottom: 33.333333%;
	position: relative;
	transition: all .5s ease-out .2s;
	
	& > i {
		position: absolute;
			top: 50%;
			left: 50%;
		font-size: 3rem;
		transition: all .5s ease-out;
		transform: translate(-50%, -50%);
	}
	
	&.-bg-yellow {	
		& > i {
			color: $blue;
		}
	}
	&.-bg-orange {
		& > i {
			color: $red;
		}
	}
	&.-bg-green {
		& > i {
			color: $pink;
		}
	}
	
	&:hover {

		&.-bg-yellow {
			background: $blue;
			opacity: 1;
			& > i {
				color: $yellow;
			}
		}
		&.-bg-orange {
			background: $red;
			opacity: 1;
			& > i {
				color: $orange;
			}
		}
		&.-bg-green {
			background: $pink;
			opacity: 1;
			& > i {
				color: $green;
			}
		}
		
	}
}

.slide-container,
.mobile-slide-container {
	min-height: 400px;
	& > .image {
		min-height: 200px;
	}
	.copy {
		p {
			color: $pink;
		}
	}
}

.vendor-pin {
	position: relative;
	
	.pin-progress-bar {
		position: absolute;
		z-index: 200;
			top: 0;
			left: 0;
		width: 100%;
		height: .4rem;
		transform-origin: left;
	}
	.logo {
		padding-bottom: 0px;
		margin-bottom: 0px;
		max-height: 40px;
	}
}

@media only screen and (min-width: 640px) {
	.half-block {
		&.-bg-image {
			padding-bottom: 50%;
		}
	}
}