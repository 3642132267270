// BG - Image
$bg-size-def: cover;
$bg-rep-def: no-repeat;

// Def padding
$section-def-padding-top: 10rem;
$section-def-padding-bot: 10rem;
$section-def-padding-left: 8vw;
$section-def-padding-right: 8vw;

// Grid Size
$grid-max-width: 90rem;

// Font sizes
$font-reg: 400;
$font-bold: 500;

//
// Colors
//
$white: #fff;
$grey: #DDDEDA;
$dark-grey: #9A9A9A;
$black: #000;
$red: #9A2629;
$orange: #ED7D43;
$yellow: #FFE139;
$blue: #82B5C9;
$pink: #EABBCE;
$green: #0D645B;

$colors: (
	white: #fff,
	grey: #DDDEDA,
	darkGrey: #9A9A9A,
	black: #000,
	red: #9A2629,
	orange: #ED7D43,
	yellow: #FFE139,
	blue: #82B5C9,
	pink: #EABBCE,
	green: #0D645B
);

//
// Easings
//
$in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

@media only screen and (min-width: 0px) {
	html {
		font-size: 85% !important;
	}
}
@media only screen and (min-width: 640px) {
	html {
		font-size: 90% !important;
	}
}
@media only screen and (min-width: 1024px) {
	html {
		font-size: 95% !important;
	}
}
@media only screen and (min-width: 1200px) {
	html {
		font-size: 100% !important;
	}
}
@media only screen and (min-width: 1400px) {
	html {
		font-size: 105% !important;
	}
}