.D\(ib\) {
  display: inline-block;
}
.Fz\(1\.5rem\)\! {
  font-size: 1.5rem !important;
}
.Fz\(3rem\) {
  font-size: 3rem;
}
.H\(100\%\) {
  height: 100%;
}
.H\(100\%\)\! {
  height: 100% !important;
}
.Mx\(1rem\) {
  margin-left: 1rem;
  margin-right: 1rem;
}
.My\(4rem\) {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.Mt\(1rem\) {
  margin-top: 1rem;
}
.Mt\(2rem\) {
  margin-top: 2rem;
}
.Mt\(3rem\) {
  margin-top: 3rem;
}
.Mend\(1\.3rem\) {
  margin-right: 1.3rem;
}
.Mend\(1\.5rem\) {
  margin-right: 1.5rem;
}
.Mb\(1rem\) {
  margin-bottom: 1rem;
}
.Mb\(2rem\) {
  margin-bottom: 2rem;
}
.Mb\(3rem\) {
  margin-bottom: 3rem;
}
.Mb\(5rem\) {
  margin-bottom: 5rem;
}
.End\(0\) {
  right: 0;
}
.B\(0\) {
  bottom: 0;
}
.Start\(0\) {
  left: 0;
}
.P\(0px\)\! {
  padding: 0px !important;
}
.P\(3rem\) {
  padding: 3rem;
}
.Px\(3rem\) {
  padding-left: 3rem;
  padding-right: 3rem;
}
.Px\(8vw\) {
  padding-left: 8vw;
  padding-right: 8vw;
}
.Py\(5rem\) {
  padding-top: 5rem;
}
.Py\(5rem\), .Pb\(5rem\) {
  padding-bottom: 5rem;
}
.Pt\(2rem\) {
  padding-top: 2rem;
}
.Pt\(3rem\) {
  padding-top: 3rem;
}
.Pend\(20vw\) {
  padding-right: 20vw;
}
.Pos\(a\) {
  position: absolute;
}
.Pos\(r\) {
  position: relative;
}
.W\(100\%\) {
  width: 100%;
}
.W\(100\%\)\! {
  width: 100% !important;
}
@media screen and (max-width: 640px) {
  .Fz\(1\.5rem\)\!--sm {
    font-size: 1.5rem !important;
  }
  .Mb\(3rem\)--sm {
    margin-bottom: 3rem;
  }
  .P\(3rem\)--sm {
    padding: 3rem;
  }
}
@media screen and (min-width: 640px) {
  .Fz\(1\.5rem\)--md {
    font-size: 1.5rem;
  }
  .Fz\(3rem\)--md {
    font-size: 3rem;
  }
  .Fz\(1\.5rem\)\!--md {
    font-size: 1.5rem !important;
  }
  .Mt\(1rem\)--md {
    margin-top: 1rem;
  }
  .Mb\(0rem\)\!--md {
    margin-bottom: 0rem !important;
  }
  .Pt\(6rem\)--md {
    padding-top: 6rem;
  }
}
@media screen and (min-width: 1025px) {
  .Fz\(2\.25rem\)\!--lg {
    font-size: 2.25rem !important;
  }
  .Fz\(3rem\)--lg {
    font-size: 3rem;
  }
  .Fz\(6rem\)--lg {
    font-size: 6rem;
  }
  .Mt\(0rem\)--lg {
    margin-top: 0rem;
  }
}
