
// Fonts
@mixin font(
$style: normal,
$variant: normal,
$weight: 400,
$size: 1em,
$line-height: 1.2em,
$font: "GT Cinetype",
$color: #000) {
	
	font: $style $variant $weight #{$size}/#{$line-height} $font;
	color: $color;
	text-transform: none;
}

// Font-face

@font-face {
    font-family: 'GT Cinetype';
    src: url('fonts/gt-cinetype-regular-webfont.woff2') format('woff2'),
         url('fonts/gt-cinetype-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'GT Cinetype';
    src: url('fonts/gt-cinetype-bold-webfont.woff2') format('woff2'),
         url('fonts/gt-cinetype-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}