/*
	@mixin font(
	$style: normal,
	$variant: normal,
	$weight: 400,
	$size: 1em,
	$line-height: 1.2em,
	$font: "Gibson",
	$color: #000) 
	
*/



// ---------- Headers ------------- //

// Header One

h1,
.header-one {
	@include font(
		$size: 4rem,
		$line-height: 1.1em,
	);
	font-size: 3rem;
	letter-spacing: .02em;
}

@media only screen and (min-width: 640px) {
	.header-one {
		font-size: 3rem;
	}
}

@media only screen and (min-width: 1024px) {
	.header-one {
		font-size: 4rem;
	}
}

// Header Two

h2,
.header-two {
	@include font(
		$size: 2.25rem,
		$line-height: 1.1em,
	);
	letter-spacing: .02em;
}

// Header Three

h3,
.header-three {
	@include font(
		$size: 2.25rem,
		$line-height: 1.5em,
		$weight: 700,
	);
	letter-spacing: .02em;
}

// Header Four

h4,
.header-four {
	@include font(
		$size: .9rem,
		$line-height: 1.8em,
		$weight: 700,
	);
	letter-spacing: .11em;
}

// Body

p,
.paragraph,
ul {
	@include font(
		$size: 1rem,
		$line-height: 1.7em,
		$weight: 700,
	);
	letter-spacing: .05em;
}

ul {
	list-style-type: disc;
	& > li {
		margin-bottom: 1rem;
	}
}



// ---------- Variants ------------- //

// Styles
.-underline {
	border-bottom: 2px solid #000;
	padding-bottom: .5rem;
	display: inline-block;
	
	@each $name, $color in $colors {
		&.-color-#{$name} {
			border-color: #{$name};
		}
	}
}
.-center {
	@extend .text-center;
	
	&:after {
		left: 50%;
		transform: translateX(-50%);
	}
}

.-title {
	text-transform: capitalize;
}

strong,
.-thicc {
	font-weight: 700;
}

.-light {
	font-weight: 300;
}

// Colors
.-color {
	@each $name, $color in $colors {
		&-#{$name} {
			color: $color;
		}
	}
}

// ---------- Other Tags ------------- //
