.tenant-ticker {
	@extend .grid-x;
	@extend .align-middle;
	@extend .align-center;
	padding: 2rem 8vw;
	
	& > .tenant {
		max-height: 65px;
		padding-left: 2rem;
		padding-right: 2rem;
		margin-bottom: 2rem;
		text-align: center;
	
		& > img {
			max-height: 65px;
		}
	}
}

.mobile-ticker {
	.cell {
		padding-left: 1rem;
	}
	img {
		height: 100px;
		width: auto !important;
		margin-left: 1.5rem;
		margin-right: 1.5rem;
	}
}

.nav {
	list-style-type: none;
	margin-left: 0px;
	text-align: center;
	
	& > li {
		display: inline-block;
	}
	
	&.-pink {
		.nav-circle {
			border-color: $pink;
			&:after {
				background: $pink;
			}
		}
	}
}
.nav-circle {
	height: 15px;
	width: 15px;
	border-radius: 100px;
	display: inline-block;
	border: 2px solid #000;
	margin-left: .5rem;
	margin-right: .5rem;
	overflow: hidden;
	position: relative;
	
	&:after {
		display: block;
		content: "";
		position: absolute;
			top: 0;
			left: 0;
		width: 100%;
		height: 100%;
		background: #000;
		transform: translateX(-100%);
		transition: all .4s ease-out;
	}
	
	&:hover {
		opacity: 1;
		&:after {
			transform: translateX(-50%);
		}
	}
	&.active {
		&:after {
			transform: translateX(-0%);
		}
	}
}