section {

}

.banner {
	background: $pink;
}

.-abs-center {
	position: absolute;
		top: 50%;
		left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	padding: 4rem;
}

.-bg {
	@each $name, $color in $colors {
		&-#{$name} {
			background-color: $color;
		}
		-white {
			background: #fff;
		}
	}
}

.dropdown {
	cursor: pointer;
	& > .header {
		position: relative;
		padding: 2rem;
		h2 {
			margin-bottom: 0rem;
		}
	}
	.arrow {
		position: absolute;
			top: 50%;
			right: 2rem;
		transform: translateY(-50%) rotate(180deg);
		width: 25px;
		transition: all .3s ease-out;
	}
	& > .content {
		padding: 0rem 2rem;
		padding-top: 0rem; 
		max-height: 0px;
		overflow: hidden;
		transition: all .5s ease-out;
		p {
			&:last-of-type {
				margin-bottom: 0px;
			}
		}
	}
	
	&.-active {
		& > .content {
			padding: 2rem;
			padding-top: 0rem;
			max-height: 400px;
		}
		.arrow {
			transform: translateY(-50%) rotate(0deg);
		}
	}
}

.maps {
	position: relative;
	
	& > .vendor-map {
		position: absolute;
		z-index: 1;
			top: 0;
			left: 0;
		width: 100%;
		opacity: 0;
		transition: opacity 0s ease-out .3s;
		
		&.-active {
			opacity: 1;
			z-index: 2;
			transition: opacity .3s ease-out 0s;
		}
	}
}