// --------- Interactive Elements --------- //

// Links
.link {
	
}

a {
	transition: all .3s ease-out;
	color: #000;
	
	&:hover {
		opacity: .6;
		color: #000;
	}
}

// Buttons
.button {
	padding: .5rem 1.6rem;
	background: $red;
	@extend .paragraph;
	color: $orange;
	cursor: pointer;
	transition: all .3s ease-out;
	border: 2px solid transparent;
	border-radius: 0px;
	
	&.-green {
		background: $green;
		color: $pink;
	}
	&.-yellow {
		background: $yellow;
		color: $blue;
	}
	&.-orange {
		background: $orange;
		color: $red;
	}
	&.-pink {
		background: $pink;
		color: $green;
	}
	
	&.-outline {
		background: transparent;
		@each $name, $color in $colors {
			&.-#{$name} {
				border: 2px solid $color;
			color: $color;
			}
		}
	}
	
	&:hover {
		color: $red;
		background: transparent;
		border: 2px solid $red;
		opacity: 1;
		
		&.-green {
			color: $green;
			background: transparent;
			border: 2px solid $green;
		}
		&.-yellow {
			background: transparent;
			border: 2px solid $yellow;
			color: $yellow;
		}
		&.-orange {
			background: transparent;
			border: 2px solid $orange;
			color: $orange;
		}
		&.-pink {
			background: transparent;
			border: 2px solid $pink;
			color: $pink;
		}
		
		&.-outline {
			border: 2px solid transparent;
			
			&.-green {
				color: $pink;
				background: $green;
			}
			&.-yellow {
				background: $blue;
				color: $yellow;
			}
			&.-blue {
				background: $yellow;
				color: $blue;
			}
			&.-orange {
				background: $orange;
				color: $red;
			}
			&.-pink {
				background: $pink;
				color: $green;
			}
		}
	}
}

.sm-icon {
	transition: all .5s ease-out;
	
	&:hover {
		opacity: 1;
		color: $red;
		
		i {
			color: $red;
		}
	}
}

// --------- Inputs ----------- //

// Select
select {
	border-radius: 0px;
}


// Inputs
input,
textarea {
	appearance: none;
	border: none;
	outline: none;
	box-shadow: none;
	border-bottom: 2px solid #000;
	background: transparent;
	padding: .3rem 0rem;
	color: #000;
	width: 100%;
	margin-bottom: 3rem;
	border-radius: 0px;
	@extend .header-two;
	
	&::Placeholder {
		color: #000;
	}
}

.form-table {
	border-collapse: collapse;
	border: 2px solid $green;
	border-spacing: 0px;
	
	td {
		border: 2px solid $green;
		transition: all .4s ease-out;
	}
	tr {
		vertical-align: bottom;
	}
	input,
	textarea {
		height: 53px;
		width: 100%;
		border: none;
		background: $pink;
		color: $green !important;
		font-size: 1.2rem;
		padding: .8rem 1rem;
		margin-bottom: 0rem;
		transition: all .4s ease-out;
		resize: none;
		border-radius: 0px;
		
		&::Placeholder {
			color: $green;
			transition: all .4s ease-out;
		}
		&:hover {
			background: $green;
			color: $pink !important;
			
			&::Placeholder {
				color: $pink;
			}
		}
		&:focus {
			background: $green;
			color: $pink !important;
			box-shadow: none;
			outline: none;
			
			&::Placeholder {
				opacity: .5;
				color: $pink;
			}
		}
	}
	textarea {
		display: block;
		height: 250px;
	}
}
