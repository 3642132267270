.main-header {
	padding: 1.8rem 8vw;
	text-align: center;
	position: fixed;
		top: 0;
		left: 0;
	width: 100%;
	background: #fff; 
	z-index: 900;
	
	
	& > img {
		width: 200px;
		margin: 0 auto;
		display: inline-block;
	}
}

.header-spacer {
	height: 90px;
}