body {
	overflow-x: hidden;
	
	&.-locked {
		overflow: hidden;
		display: block;
	}
	&.is-loading {
		& * {
			transition: all 0s !important;
		}
	}
}
main {
	overflow-x: hidden;
	overflow-y: hidden;
}

html {
	font-size: 85%;
}
@media only screen and (min-width: 640px) {
	html {
		font-size: 90%;
	}
}
@media only screen and (min-width: 1026px) {
	html {
		font-size: 95%;
	}
}
@media only screen and (min-width: 1336px) {
	html {
		font-size: 105%;
	}
}